<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('courseprerequisites')" :isNewButton="checkPermission('courseprerequisite_store')"
                    :isColumns="true" @new-button-click="createFormShow()"
                    @filter-div-status="datatable.filterStatus = $event" :is-import-button="true"
                    @import-button-click="importData">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('faculties')" :isNewButton="checkPermission('courseprerequisite_store')"
                    :isColumns="true" @new-button-click="createFormShow()"
                    @filter-div-status="datatable.filterStatus = $event" :is-import-button="true"
                    @import-button-click="importData">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                :exportExcel="checkPermission('courseprerequisite_excelexport') || true" @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('code')">
                            <b-form-input v-model="datatable.queryParams.filter.code">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('program_code')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_codes" :multiple="true" :is-active="true">
                            </program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('course_status')">
                            <status-selectbox
                                :setOptions="[{ text: $t('Active'), value: 'a' }, { text: $t('Passive'), value: 'p' }]"
                                v-model="datatable.queryParams.filter.course_status"></status-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"
                v-if="checkPermission('courseprerequisite_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess" v-if="formProcess == 'create'" />
                </template>
            </CommonModal>

            <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"
                v-if="checkPermission('courseprerequisite_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId" @updateFormSuccess="updateFormSuccess" v-if="formProcess == 'update'" />
                </template>
            </CommonModal>
            <CommonModal ref="importFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('import').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <import-form @successProcess="importFormSuccess" v-if="formProcess == 'import'" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
//Other
import qs from "qs";
//Service
import CoursePrerequisitesService from "@/services/CoursePrerequisitesService";
//Page
import CreateForm from "./CreateForm";
import UpdateForm from "./UpdateForm";
import ImportForm from "@/modules/coursePrerequisites/pages/ImportExcel.vue";


export default {
    components: {
        ImportForm,
        AppLayout,
        Header,
        HeaderMobile,
        CreateForm,
        UpdateForm,
        DatatableFilter,
        Datatable,
        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t("courseprerequisites"),
        };
    },
    data() {
        return {
            formId: 0,
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: 'courseprerequisite_update',
                                callback: (row) => {
                                    this.updateFormShow(row.id)
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: 'courseprerequisite_delete',
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t('course_id'),
                        field: 'course_id',
                        sortable: true,
                    },
                    {
                        label: this.$t('course_code'),
                        field: 'course_code',
                        sortable: false,
                    },

                    {
                        label: this.$t('course_name'),
                        field: 'course_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('course_status'),
                        field: 'course_status',
                        sortable: false,
                    },
                    {
                        label: this.$t('prerequisites_text'),
                        field: 'prerequisite_text',
                        sortable: false
                    },
                    {
                        label: this.$t('programs'),
                        field: 'program_names',
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "course_id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            };

            return CoursePrerequisitesService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        delete(id) {
            this.deleteModal(() => {
                CoursePrerequisitesService.deleteCoursePrerequisites(id)
                    .then((response) => {
                        this.filter();
                        this.$toast.success(this.$t("api." + response.data.message));
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t("api." + error.data.message));
                    });
            });
        },
        decodeHTMLEntities(text) {
            const textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        async updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        updateFormShow(id) {
            this.formId = id
            this.formProcess = 'update'
            this.$refs.updateFormModal.$refs.commonModal.show()
        },
        createFormShow() {
            this.formId = null
            this.form = {};
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        importData() {
            this.formProcess = 'import'
            this.$refs.importFormModal.$refs.commonModal.show()
        },
        importFormSuccess() {
            this.formProcess = null;
            this.$refs.importFormModal.$refs.commonModal.hide()
        },
        async exportExcel() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                    limit: "-1"
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            };

            return CoursePrerequisitesService.getAll(config)
                .then((response) => {

                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                    const rows = response.data.data.map(row=>{
                        return {
                            ...row,
                            course_name:this.decodeHTMLEntities(row.course_name),
                            department_names: this.decodeHTMLEntities(row.department_names)
                        }
                    })
                    const headers = [
                        { header: this.$t('id'), key: "id", width: 20, hidden: true },
                        { header: this.$t('course_id'), key: "course_id", width: 30 },
                        { header: this.$t('course_code'), key: "course_code", width: 30 },
                        { header:this.$t('course_name'), key: "course_name", width: 30 },
                        { header:this.$t('prerequisites'), key: "prerequisite_text", width: 30 },
                        { header:this.$t('programs'), key: "program_names", width: 30 },
                    ]
                    this._downloadExcelFile({ data: rows, filename: `${this.$t('precondition')}.xlsx`, headers })
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        }
    },
};
</script>

