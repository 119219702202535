<template>
    <div class="row mb-5 align-items-center">
        <div class="col-12">
            <ValidationObserver ref="storeForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="course_id" rules="required"
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('course')">
                                <course-auto-complete v-model="form.course_id"
                                                      :valueObject="{value: form.course_id, text: form.course_code + ' - ' + form.course_name}">
                                </course-auto-complete>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="can_take_same_semester" rules="required"
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('can_take_same_semester')">
                                <true-false-selectbox v-model="form.can_take_same_semester" :options="[
                        {value: null, text: ''},
                        {value: 1, text: $t('yes')},
                        {value: 0, text: $t('no')}
                    ]"/>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="department_codes" v-slot="{ valid, errors }" rules="required">
                            <b-form-group :label="$t('program_code')">
                                <program-selectbox v-model="form.program_codes" :multiple="true" :is-active="true">
                                </program-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="prerequisites" rules=""
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('prerequisites')">
                                <course-auto-complete v-model="prerequisitess" v-on:courseSelected="courseSelected">
                                </course-auto-complete>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12" v-if="courses && courses.length">
                        <table class="table table-bordered">
                            <tr v-for="c in courses" v-bind:key="c.id">
                                <td>{{ c.text }}</td>
                                <td class="width-30">
                                    <b-button class="text-danger mr-1" variant="link" size="xs"
                                              @click="deleteCourse(c.value)">
                                        {{ $t('delete').toUpper() }}
                                    </b-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="col-12 mt-3 d-flex">
            <b-button @click="createForm" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t('save').toUpper() }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Component
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox";
//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
//Service
import CoursePrerequisitesService from "@/services/CoursePrerequisitesService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CourseAutoComplete,
        DepartmentSelectbox,
        TrueFalseSelectbox
    },
    props: {
        formId: {
            type: Number,
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    data() {
        return {
            formLoading: false,
            prerequisitess: null,
            data: null,
            form: {
                department_codes: null,
            },
            courses: [],
            course: null,
        }
    },
    methods: {
        get(id) {
            this.formLoading = true;
            CoursePrerequisitesService.get(id)
                .then((response) => {
                    this.form = response.data.data;
                    this.course = {value: this.form.course_id, text: this.form.course_code};
                    for (let i = 0; i < this.form.prerequisites.length; i++) {
                        this.courses.push({value: this.form.prerequisites[i], text: this.form.prerequisites_text[i]});
                    }
                    this.formLoading = false;

                })
                .catch((error) => {
                    this.showErrors(error, this.$refs.storeForm)
                })
        },
        async createForm() {
            const isValid = await this.$refs.storeForm.validate()
            if (isValid) {
                this.data = {...this.form};
                if (this.data.can_take_same_semester === false) {
                    this.data.can_take_same_semester = 0;
                }
                if (!this.courses.length) {
                    return;
                }
                this.data.prerequisites = [];
                this.courses.forEach(item => {
                    this.data.prerequisites.push(parseInt(item.value));
                });
                CoursePrerequisitesService.update(this.formId, this.data)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit('updateFormSuccess', true);
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.storeForm)
                    })

            }
        },
        courseSelected(course) {
            if (this.courses.filter(item => {
                return item.value == course.value;
            }).length) {
                return;
            }
            this.courses.push(course);
        },
        deleteCourse(id) {
            this.courses = this.courses.filter(item => {
                return item.value != id;
            });
        }
    }
}
</script>
